import { Container, ContainerSucces } from './styles';
import { useForm, ValidationError } from '@formspree/react';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import validator from 'validator';
import 'react-toastify/dist/ReactToastify.css';

const YOUR_EMAIL = 'hasankhodabakhshi@gmail.com';

export function Form() {
  const [state, handleSubmit] = useForm('xknkpqry');
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const verifyEmail = (email: string) => {
    setValidEmail(validator.isEmail(email));
  };

  useEffect(() => {
    if (state.succeeded) {
      toast.success('Email successfully sent!', {
        position: 'bottom-left',
        pauseOnFocusLoss: false,
        closeOnClick: true,
        hideProgressBar: false,
        toastId: 'succeeded',
      });
    }
  }, [state.succeeded]);

  if (state.succeeded) {
    return (
      <ContainerSucces>
        <h3>با تشکر برای تماس</h3>
        <button
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          بازگشت به بالا
        </button>
        <ToastContainer />
      </ContainerSucces>
    );
  }

  return (
    <Container>
      <h2>با استفاده از فرم تماس بگیرید</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (validEmail) {
            handleSubmit({ email: YOUR_EMAIL, message });
          }
        }}
      >
        <input
          placeholder="Email"
          id="email"
          type="email"
          name="email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            verifyEmail(e.target.value);
          }}
          required
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
        <textarea
          required
          placeholder="Send a message to get started."
          id="message"
          name="message"
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setMessage(e.target.value);
          }}
        />
        <ValidationError prefix="Message" field="message" errors={state.errors} />
        <button type="submit" disabled={!validEmail}>
          ارسال
        </button>
      </form>
      <ToastContainer />
    </Container>
  );
}
